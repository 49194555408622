<template>
  <Sidebar ref="sidebar" :title="selectedComponentType ? selectedComponentType.label : $tuf('new_component')">

    <!-- 1. grid with available components -->
    <div v-if="step === 0" class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
      <button
        type="button"
        v-for="(componentType, type) in componentTypes"
        :key="type"
        @click.prevent="selectComponentType(componentType)"
        class="bg-white rounded overflow-hidden shadow hover:bg-gray-100 focus:bg-gray-100 focus:outline-none"
      >
        <span class="block w-full h-20 bg-no-repeat bg-center" :style="`background-image: url('${componentType.icon}')`"></span>
        <span class="block truncate p-2 text-sm">{{ componentType.label }}</span>
      </button>
    </div>

    <!-- 2. form with available fields of selected component -->
    <form v-if="step === 1" @submit.prevent="save" class="pb-20">

      <Overlay v-if="loading" class="z-2 mt-16 flex justify-center items-center bg-white">
        <div class="bg-white shadow rounded w-8 h-8 flex justify-center items-center text-prasset-green-500">
          <LoadingIndicator />
        </div>
      </Overlay>

      <button
        type="button"
        class="button button--outlined button--small text-sm mb-4"
        @click.prevent="gotoFirstStep"
      >
        &lt; {{ $tuf('choose_other_component') }}
      </button>

      <div class="relative z-1 grid grid-cols-6 gap-6">
        <FieldsGenerator
          v-if="selectedComponentType && selectedComponentType.content_fields"
          :errors="errors"
          :fields="selectedComponentType.content_fields"
          :data="form.content"
          :fileCollection="form.media"
          :entryId="form.id"
          entryType="websitecomponent"
          class="col-span-6"
        />

        <FieldsGenerator
          v-if="selectedComponentType && selectedComponentType.options_fields"
          :errors="errors"
          :fields="selectedComponentType.options_fields"
          :data="form.options"
          :entryId="form.id"
          entryType="websitecomponent"
          class="col-span-6 border-t border-gray-300 pt-4"
        />
      </div>

      <div class="w-full bg-white border-t border-gray-300 absolute bottom-0 inset-x-0 px-5 py-4 z-20">
        <div class="flex">
          <button class="button button--outlined mr-4" type="button" @click="$refs.sidebar.close()">
            {{ $tuf('cancel') }}
          </button>

          <button
            type="submit"
            class="button button--opague relative"
            :class="{ 'opacity-25': loading && submittedBy !== 'save-and-back' }"
            :disabled="loading"
          >
            <span :class="{ 'invisible': loading && submittedBy === 'save-and-back' }">
              {{ $tuf('save_and_back') }}
            </span>
            <span v-if="loading && submittedBy === 'save-and-back'" class="absolute inset-0 flex justify-center items-center">
              <LoadingIndicator />
            </span>
          </button>
        </div>
      </div>
    </form>
  </Sidebar>
</template>

<script>
import { generateUUID } from '@/providers/helpers';
import { toRefs, reactive, computed, onMounted } from '@vue/composition-api';
import FieldsGenerator from '@/modules/core/views/components/FieldsGenerator';
import LoadingIndicator from '@/components/LoadingIndicator';
import Overlay from '@/components/Overlay';
import Sidebar from '@/modules/core/views/components/Sidebar';
import useWebsite from '@/compositions/useWebsite';
import useChangedState from '@/compositions/useChangedState';

export default {
  name: 'ComponentCreate',

  components: {
    FieldsGenerator,
    LoadingIndicator,
    Overlay,
    Sidebar,
  },

  setup(props, { refs, root }) {
    const { project_id, page_id } = root.$route.params;
    const { createComponent, componentTypes, loadComponentTypes, reloadPage } = useWebsite();
    const { changed, watchChanges, commitChanges } = useChangedState();

    const state = reactive({
      form: {
        id: generateUUID(),
        holder: {
          type: 'page',
          id: page_id,
          slot: 'PAGE_SLOT',
        },
        component: null,
        name: null,
        content: {},
        options: {},
      },
      errors: {},
      ready: false,
      changed,
      loading: false,
      submittedBy: null,
      components: {},
      step: 0,
    });

    /**
     * Store selected component and go to the next step.
     */
    function selectComponentType(componentType) {
      state.form.component = componentType.name;
      state.form.name = componentType.label;
      state.form.options = componentType.default_options;
      state.step = 1;
    }

    function gotoFirstStep() {
      state.form.component = null;
      state.step = 0;
    }

    /**
     * Selected component object.
     */
    const selectedComponentType = computed(() => {
      return componentTypes.value[state.form.component] || null;
    });


    /**
     * Load form data.
     */
    async function load() {
      state.loading = true;

      await loadComponentTypes(project_id);

      state.loading = false;
      state.ready = true;

      watchChanges(state, 'form');
    }

    /**
     * Save form data.
     */
    async function save(close = true, submittedBy = 'save-and-back') {
      state.submittedBy = submittedBy;
      state.loading = true;
      state.errors = {};

      try {
        await createComponent(project_id, state.form);
        await reloadPage(project_id, page_id);

        root.$notify({ type: 'success', title: 'Succes', text: 'Onderdeel is aangemaakt' });

        state.loading = false;

        commitChanges(state, 'form');

        if (close) {
          refs.sidebar.close();
        }
      } catch (error) {
        state.loading = false;
        state.errors = error.response.data.errors;
      }
    }

    onMounted(async () => {
      load();
    });

    return {
      ...toRefs(state),
      save,
      selectComponentType,
      selectedComponentType,
      gotoFirstStep,
      componentTypes,
    };
  },
};
</script>
